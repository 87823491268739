import { Button } from "@headlessui/react";
import { NavLink } from "react-router-dom";

export default function Front() {
  return (
    <div className="w-full">
      {/* First Full-Screen Section */}
      <div className="relative min-h-screen bg-cover bg-center bg-bc-front-1 flex flex-col justify-center items-center pt-10">
        {/* Text moved to the top-left corner */}
        <div className="absolute top-10 left-10 text-left text-white">
          {/* Separate container for the logo */}
          <div className="relative" style={{ left: "-100px" }}>
            {" "}
            {/* Adjust this left value to move the logo more to the left */}
            <img
              src="/assets/images/siemens_logo.png"
              alt="Siemens Gamesa"
              className="h-30 w-auto max-w-[550px] drop-shadow-lg"
            />
          </div>
          {/* Text content */}
          <div
            className="mt-4 text-2xl font-light text-white leading-relaxed drop-shadow-lg"
            style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)" }}
          >
            <p>
              To see LTC wind data prediction, choose from one of the available
              <span className="text-[#0D9CA5]"> locations</span> below - MMIJ or
              FINO.
              <br></br>
              For more details about locations and models scroll to the{" "}
              <span className="text-[#0D9CA5]">bottom</span> of the page.
              <br></br>
              For more information about the project, please go to the
              <NavLink
                to="/about"
                className="text-[#0D9CA5] hover:text-cyan-300"
              >
                {" "}
                about page
              </NavLink>
              .
            </p>
          </div>
        </div>

        {/* Buttons centered */}
        <div className="mt-20 flex justify-center space-x-20">
          <NavLink to="location/mmij">
            <Button className="relative rounded-3xl border-2 border-[#0D9CA5] border-opacity-70 py-4 px-8 text-[#0D9CA5] bg-white bg-opacity-80 hover:bg-opacity-90 transition duration-300">
              <span className="block text-3xl font-bold tracking-[0.4em]">
                MMIJ
              </span>
              <span className="block text-m tracking-normal mt-1">
                N52°50.89' E3°26.14'
              </span>
            </Button>
          </NavLink>
          <NavLink to="/location/fino">
            <Button className="relative rounded-3xl border-2 border-[#0D9CA5] border-opacity-70 py-4 px-8 text-[#0D9CA5] bg-white bg-opacity-80 hover:bg-opacity-90 transition duration-300">
              <span className="block text-3xl font-bold tracking-[0.4em]">
                FINO1
              </span>
              <span className="block text-m tracking-normal mt-1">
                N52°50.89' E3°26.14'
              </span>
            </Button>
          </NavLink>
        </div>
      </div>

      {/* Section with Locations */}
      <div className="min-h-screen bg-cover bg-center bg-bc-front-2 flex flex-col justify-center items-center">
        {/* Overlay for better text readability */}
        <div className="w-full h-full bg-gradient-to-b from-transparent to-[#ffffff]  bg-opacity-60 flex flex-col justify-center items-center py-16 px-6">
          {/* Introduction Text */}
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-800">
              Learn More About The Project
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-700">
              We utilized data from two research platforms—MMIJ and FINO—to
              train machine learning models for predicting wind conditions and
              energy generation at these sites.<br></br> Scroll down for more
              insights into the models and predictions at each location.{" "}
            </p>
          </div>

          {/* Locations Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* MMIJ Location */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800">
                MMIJ Research Platform
              </h3>
              <p className="text-gray-600 mt-2 font-medium">
                <strong>Location:</strong> N52°50.89', E3°26.14'
              </p>
              <p className="mt-4 text-gray-600 font-medium">
                The MMIJ platform, located in the North Sea, serves as a vital
                data source for training machine learning models. This data has
                been instrumental in predicting wind conditions and estimating
                energy generation at the site.{" "}
              </p>
            </div>

            {/* FINO1 Location */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800">
                FINO1 Research Platform
              </h3>
              <p className="text-gray-600 mt-2 font-medium">
                <strong>Location:</strong> N53°50.89', E4°26.14'
              </p>
              <p className="mt-4 text-gray-600 font-medium">
                The FINO1 platform, located near the coast of Germany, provides
                crucial data used to train models for predicting wind conditions
                and energy generation. It plays a key role in advancing
                renewable energy forecasting.{" "}
              </p>
            </div>
          </div>

          {/* Google Maps Integration */}
          <div className="mt-12 w-full h-96">
            <iframe
              title="Wind Farm Locations"
              className="w-full h-full rounded-lg shadow-lg"
              src={`https://www.google.com/maps/embed/v1/view?key=YOUR_GOOGLE_MAPS_API_KEY&center=52.8489,3.4356&zoom=6&maptype=roadmap&markers=52.8489,3.4356|53.8489,4.4356`}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* Placeholder for Future Sections */}
      <div className="min-h-screen bg-white py-16">
        {/* Machine Learning Models Section */}
        <div className="py-16">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
            Machine Learning Models
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-6">
            {/* Model 1 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800">
                Model 1: Linear Regression
              </h3>
              <p className="mt-4 text-gray-600">
                Linear Regression is a basic model used to predict wind speed
                based on historical data. It's simple but effective for initial
                wind condition estimation.
              </p>
              <ul className="mt-4 text-gray-600">
                <li>
                  <strong>Hyperparameters:</strong> Learning Rate, Number of
                  Features
                </li>
              </ul>
            </div>

            {/* Model 2 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800">
                Model 2: Random Forest
              </h3>
              <p className="mt-4 text-gray-600">
                Random Forest is an ensemble learning model that uses multiple
                decision trees to improve the accuracy of wind condition
                predictions.
              </p>
              <ul className="mt-4 text-gray-600">
                <li>
                  <strong>Hyperparameters:</strong> Number of Trees, Maximum
                  Depth
                </li>
              </ul>
            </div>

            {/* Model 3 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800">
                Model 3: Neural Networks
              </h3>
              <p className="mt-4 text-gray-600">
                Neural Networks are used for more complex wind prediction tasks,
                learning intricate patterns in the data to predict wind
                conditions with higher accuracy.
              </p>
              <ul className="mt-4 text-gray-600">
                <li>
                  <strong>Hyperparameters:</strong> Number of Layers, Neurons,
                  Learning Rate
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Wind Mills Section */}
        <div className="py-16 bg-gray-50">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
            Wind Mills
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-6">
            {/* Wind Mill 1 */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img
                src="/path/to/windmill1.jpg"
                alt="Wind Mill 1"
                className="rounded-lg shadow-lg w-full h-60 object-cover mb-6"
              />
              <h3 className="text-2xl font-semibold text-gray-800">
                Wind Mill Type 1
              </h3>
              <p className="mt-4 text-gray-600">
                This windmill generates up to 3.5 MW of power and is optimized
                for offshore locations.
              </p>
            </div>

            {/* Wind Mill 2 */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img
                src="/path/to/windmill2.jpg"
                alt="Wind Mill 2"
                className="rounded-lg shadow-lg w-full h-60 object-cover mb-6"
              />
              <h3 className="text-2xl font-semibold text-gray-800">
                Wind Mill Type 2
              </h3>
              <p className="mt-4 text-gray-600">
                This model is designed for higher altitudes and produces up to 4
                MW of power, ensuring stable energy generation.
              </p>
            </div>

            {/* Wind Mill 3 */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img
                src="/path/to/windmill3.jpg"
                alt="Wind Mill 3"
                className="rounded-lg shadow-lg w-full h-60 object-cover mb-6"
              />
              <h3 className="text-2xl font-semibold text-gray-800">
                Wind Mill Type 3
              </h3>
              <p className="mt-4 text-gray-600">
                The third type of windmill is engineered for maximum efficiency
                in coastal regions, generating up to 5 MW.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
