import React from "react";
import { Route, Routes } from "react-router-dom";
import Front from "./components/containers/Front/Front";
import Locations from "./components/containers/Locations/Locations";
import About from "./components/containers/About/About";
import Navbar from "./components/Navbar";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className=" h-screen bg-neutral-800 text-slate-300 text-lg font-semibold overflow-y-hidden">
      <div className="App h-full overflow-scroll">
        <Navbar />
        <div className="h-full">
          <Routes>
            <Route path="/" element={<Front />} />
            <Route path="/about" element={<About />} />
            <Route path="/location/mmij" element={<Locations />} />
            <Route path="/location/fino" element={<Locations />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
