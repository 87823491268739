export default function About() {
  return (
    <>
      <div className="h-2/3 flex justify-center items-center">
        <p>This project was created by</p>
        <ul className="text-2xl">
          <li className="text-fuchsia-400">
            <p>Natalia Koziara</p>
          </li>
          <li className="text-sky-400">
            <p>Jakub Nowak</p>
          </li>
          <li className="text-green-400">
            <p>Mateusz Zbysław</p>
          </li>
        </ul>
        <p> at VIA University College.</p>
      </div>
      <div className="">
        <div className="flex justify-center">
          <p>[LOGO]</p>
          <p>Project on Github</p>
        </div>
        <p>Link #1</p>
        <p>Link #2</p>
        <p>Link #3</p>
        <p>Link #4</p>
        <p>Link #5</p>
      </div>
    </>
  );
}
